<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-customPage-edit" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "CustomPage.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="customPageData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="FormulaName" class="form-label required"
                >{{
                  $t(
                    "CustomPage.FormulaName",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="FormulaName"
                :value="customPageData.formulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
                disabled
              />
            </div>
            <div class="col-md-12">
              <label for="Description" class="form-label required"
                >{{
                  $t(
                    "CustomPage.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <textarea
                type="text"
                class="form-control"
                id="Description"
                v-model="customPageData.description"
                :spellcheck="this.$isTextSpellCheck"
                required
              ></textarea>
            </div>
            <div class="col-12">
              <!-- <div class="form-check">
                <label for="IsDefault" class="form-label">{{
                  $t(
                    "CustomPage.isDefault",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsDefault"
                  v-model="customPageData.isDefault"
                />
              </div> -->
              <div class="form-check">
                <label for="IsSwitchCustomPagesEnabled" class="form-label">{{
                  $t(
                    "CustomPage.IsSwitchCustomPagesEnabled",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsSwitchCustomPagesEnabled"
                  v-model="customPageData.isSwitchCustomPagesEnabled"
                />
              </div>
              <div class="form-check">
                <label for="IsMenuHidden" class="form-label">{{
                  $t(
                    "CustomPage.IsMenuHidden",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsMenuHidden"
                  v-model="customPageData.isMenuHidden"
                />
              </div>
              <div class="form-check">
                <label for="IsHideOnDropdown" class="form-label">{{
                  $t(
                    "CustomPage.IsHideOnDropdown",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsHideOnDropdown"
                  v-model="customPageData.isHideOnDropdown"
                />
              </div>
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="customPageData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomPageEdit",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      customPageData: [],
    };
  },
  methods: {
    getCustomPageDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CustomPageGet?id={0}",
            this.$route.params.customPageId
          )
        )
        .then((response) => {
          this.customPageData = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-customPage-edit");
      form.addClass("was-validated");
      if (!form[0].checkValidity()) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-CustomPageEdit", { ...this.customPageData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/CustomPage/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getCustomPageDetail();
  },
};
</script>
